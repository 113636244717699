<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0"> {{ wosId ? 'Edit' : 'New' }} publication<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to research lines"
              @click="$router.push({name: 'admin.publications.import'})"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Research lines settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Researcher</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Search researcher</label>
                        <v-select
                          v-model="publication.users"
                          multiple
                          label="custom_label"
                          :options="customUsers"
                          :get-option-key="option => option.id"
                          @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                        />
                      </div>
                      <div class="mb-1">
                        <button
                          :class="{ 'd-none': loading}"
                          class="btn btn-sm btn-warning me-1"
                          @click="matchAuthors"
                        >
                          <i data-feather="database" /> Match authors
                        </button>
                        <button
                          :class="{ 'd-none': !loading}"
                          class="btn btn-sm btn-warning me-1"
                          type="button"
                          disabled=""
                        >
                          <span
                            class="spinner-border spinner-border-sm me-1"
                            role="status"
                            aria-hidden="true"
                          />
                          <span class="ms-25 align-middle">Matching...</span>
                        </button>
                        <button
                          class="btn btn-sm btn-secondary"
                          @click="clearAuthors"
                        >
                          <i data-feather="database" /> Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <h4 class="mb-1">
                      Authors
                    </h4>
                    <div class="col-12">
                      <div id="full-wrapper">
                        <div id="full-container">
                          <div class="editor">
                            {{ publication.authors }}
                          </div>
                        </div>
                      </div>
                      <p><small>After the import the format of the authors will be changed. Ex.: Fernandez-Alfara, M; > Fernandez-Alfara M,</small></p>
                    </div>
                    <div class="col-md-7">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Title</label>
                        <input
                          v-model="publication.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Publication type</label>
                        <input
                          v-model="publication.publication_type"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Journal</label>
                        <input
                          v-model="publication.publication_name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Year</label>
                        <input
                          v-model="publication.year"
                          type="number"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >NoSerVolPages</label>
                        <input
                          v-model="publication.noservol_pages"
                          type="text"
                          class="form-control"
                          placeholder=""
                          value="Ahead"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >WOS ID</label>
                        <input
                          v-model="publication.wos_id"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >DOI</label>
                        <div class="input-group">
                          <input
                            v-model="publication.doi"
                            type="text"
                            class="form-control"
                            aria-describedby="linkToWeb"
                          >
                          <a
                            id="linkToWeb"
                            :href="publication.doi"
                            class="btn btn-outline-primary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Go to link"
                            target="_blank"
                          ><i data-feather="external-link" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-1">
                        <label
                          class="form-label"
                        >Full reference</label>
                        <quill-editor
                          v-model="publication.full_reference"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3">
                      <div class="mb-1">
                        <div class="form-check">
                          <input
                            id="inlineCheckbox1"
                            v-model="publication.duplicated"
                            :checked="publication.duplicated === 1"
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          >
                          <label
                            class="form-check-label"
                            for="inlineCheckbox1"
                          >Duplicated</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="publication.duplicated_item"
                    class="row"
                  >
                    <h5>Duplicated item:</h5>
                    <div class="col-12">
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Title
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Authors
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Full referencee
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Journal
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Year
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              NoServolPages
                            </th>
                            <th
                              style="padding:0.72rem 2rem;"
                              class="cursor-pointer"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :class="{
                                'text-danger': publication.duplicated_item.title == publication.title && publication.duplicated != 3,
                                'text-info': publication.duplicated == 3,
                              }"
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.title }}
                            </td>
                            <td
                              :class="{ 'text-danger': publication.duplicated_item.authors == publication.authors }"
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.authors }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.filtered_full_reference }}
                            </td>
                            <td
                              :class="{ 'text-danger': publication.duplicated_item.publication_name == publication.publication_name }"
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.publication_name }}
                            </td>
                            <td
                              :class="{ 'text-danger': publication.duplicated_item.year == publication.year }"
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.year }}
                            </td>
                            <td
                              :class="{ 'text-danger': publication.duplicated_item.noservol_pages == publication.noservol_pages }"
                              style="padding:0.72rem 2rem;"
                            >
                              {{ publication.duplicated_item.noservol_pages }}
                            </td>
                            <td
                              style="padding:0.72rem 2rem;"
                            >
                              <span
                                class="dis-ib"
                              >
                                <a
                                  :href="$router.resolve({name: 'researcher.publications.edit', params: { id: publication.duplicated_item.publication_id ? publication.duplicated_item.publication_id : publication.duplicated_item.id }}).href"
                                  target="_blank"
                                  class="btn btn-icon rounded-circle btn-flat-secondary waves-effect"
                                >
                                  <i data-feather="external-link" />
                                </a>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      wosId: this.$route.params.id,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      publication: 'publicationImport/item',
      users: 'users/fakeUsers',
    }),
    customUsers() {
      return this.users.map(e => {
        e.custom_label = `${e.name} (${e.roles.join(', ')})`
        return e
      })
    },
  },
  async mounted() {
    await this.$store.dispatch('publicationImport/fetchId', this.wosId)
  },
  methods: {
    async save() {
      this.loading = true
      await this.$store.dispatch('publicationImport/update', { wosId: this.wosId, publication: this.publication })

      this.loading = false
      Vue.swal('Publication updated successfully!', '', 'success').then(() => {
        this.$router.push({ name: 'admin.publications.import' })
      })
    },
    async onSearch(search) {
      if (search !== '' && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch('users/fetchFakeUsers', search)
      }
    },
    async matchAuthors() {
      this.loading = true
      await this.$store.dispatch('publicationImport/matchAuthors', { wosId: this.wosId, authors: this.publication.authors })
      this.loading = false
    },
    clearAuthors() {
      this.publication.users = []
    },
  },
}
</script>
